import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { FilterMatchMode } from "primereact/api";

const DataTableInvoices = ({
  data,
  loading,
  handleCurrentPage,
  selectedProduct,
  handleRowSelect,
}) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    price: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const { total, per_page, from, data: invoices } = data ?? {};

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <Paginator
        totalRecords={total}
        rows={per_page}
        first={from}
        onPageChange={onPageChange}
      />
    );
  };

  const onPageChange = ({ page }) => {
    handleCurrentPage(page);
  };

  const formatCurrency = (value) => {
    return Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const idBodyTemplate = ({ id }) => {
    return id < 100 ? String(id).padStart(3, "0") : String(id);
  };

  const priceBodyTemplate = ({ price }) => {
    return formatCurrency(price);
  };

  const statusBodyTemplate = ({ paid }) => {
    return paid === 1 ? "Pagado" : "Pendiente";
  };

  const header = renderHeader();

  const footer = renderFooter();

  return (
    <DataTable
      value={invoices}
      size="normal"
      showGridlines
      stripedRows
      removableSort
      dataKey="id"
      filterDisplay="row"
      emptyMessage="No se encontró ningúna factura."
      globalFilterFields={["code", "name", "category", "price"]}
      filters={filters}
      header={header}
      footer={footer}
      loading={loading}
      selectionMode="single"
      selection={selectedProduct}
      onRowSelect={handleRowSelect}
    >
      <Column
        field="id"
        header="Número de factura"
        sortable
        style={{ width: "15%" }}
        body={idBodyTemplate}
      ></Column>
      <Column
        field="quantity_pages"
        header="Cantidad de páginas"
        sortable
        style={{ width: "15%" }}
      ></Column>
      <Column
        field="price"
        header="Monto a pagar"
        body={priceBodyTemplate}
        sortable
        style={{ width: "15%" }}
      ></Column>
      <Column
        field="description"
        header="Plan"
        sortable
        style={{ width: "25%" }}
      ></Column>
      <Column
        field="paid"
        header="Estatus"
        sortable
        style={{ width: "15%" }}
        body={statusBodyTemplate}
      ></Column>
      <Column
        field="code"
        header="Acción"
        sortable
        style={{ width: "15%" }}
      ></Column>
    </DataTable>
  );
};

export default DataTableInvoices;
