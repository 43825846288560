import { Dropdown } from "primereact/dropdown";
import { Field } from "formik";

const DropdownFormField = ({ label, name, options, placeholder, rateDay }) => {
  const handleChangeCurrency = (e, form) => {
    form.setFieldValue(name, e.value);
    if (e.value === "Pesos") {
      form.setFieldValue("rate", +rateDay[0].value);
    } else if (e.value === "Bolivares") {
      form.setFieldValue("rate", +rateDay[1].value);
    }
  };

  return (
    <div className="form-field">
      <label htmlFor={name} className="control-label">
        {label}
      </label>
      <Field name={name}>
        {({ field, form }) => {
          const invalid = form.touched[name] && !field.value;
          return (
            <Dropdown
              {...field}
              placeholder={placeholder}
              options={options}
              onChange={(e) => handleChangeCurrency(e, form)}
              value={options.find((option) => option === field.value)}
              className={invalid ? "w-100 p-invalid" : "w-100"}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default DropdownFormField;
