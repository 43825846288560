import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

import CardRegister from "../../features/layouts/Login/CardRegister";
import Forms from "../../features/layouts/Login/Forms";
import LayoutLogin from "../../features/layouts/Login/LayoutLogin";
import Logo from "../../features/layouts/Logo/Logo";
import CustomErrorMessage from "../../utils/ErrorMessage/ErrorMessage";
import CustomField from "../../utils/CustomField/CustomField";
import ButtonLoading from "../../utils/ButtonLoading/ButtonLoading";

import useAxios from "../../hooks/useAxios";

const ForgotPassword = () => {
  const { data, error, loading, axiosFetch } = useAxios();

  const [searchParams] = useSearchParams();

  const { token, email } = Object.fromEntries(searchParams.entries());

  const initialValues = {
    email: "",
  };

  const initialValuesReset = {
    password: "",
    password_confirmation: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Correo electronico inválido")
      .required("Ingresé un correo electronico"),
  });

  const validationSchemaReset = Yup.object({
    password: Yup.string()
      .required("Ingrese una contraseña")
      .min(8, "Minímo 8 caracteres"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password")],
      "Las contraseñas no coinciden"
    ),
  });

  const handleSubmit = (values) => {
    if (token && email) {
      axiosFetch({
        url: "reset-password",
        method: "post",
        body: {
          ...values,
          email,
          token,
        },
      });
    } else {
      axiosFetch({
        url: "forgot-password",
        method: "post",
        body: values,
      });
    }
  };

  return (
    <>
      <LayoutLogin>
        <Logo />
        <CardRegister>
          <Forms
            title={token ? "Restablecer contraseña" : "Olvidé mi contraseña"}
            initialValues={token ? initialValuesReset : initialValues}
            validationSchema={token ? validationSchemaReset : validationSchema}
            handleSubmit={handleSubmit}
          >
            {data ? (
              <h3 className="text-center">{data.message}</h3>
            ) : (
              <>
                {token ? (
                  <>
                    <div className="form-group pos_rel mb-3">
                      <div>
                        <CustomField
                          name="password"
                          className="form-control lgn_input"
                          type="password"
                          placeholder="Nueva contraseña"
                          autoComplete="current-password"
                        />
                        <FontAwesomeIcon icon="lock" className="lgn_icon" />
                      </div>
                      <CustomErrorMessage name="password" />
                    </div>

                    <div className="form-group pos_rel mb-3">
                      <div>
                        <CustomField
                          name="password_confirmation"
                          className="form-control lgn_input"
                          type="password"
                          placeholder="Confirmar contraseña"
                          autoComplete="current-password"
                        />
                        <FontAwesomeIcon icon="lock" className="lgn_icon" />
                      </div>
                      <CustomErrorMessage name="password_confirmation" />
                    </div>
                  </>
                ) : (
                  <div className="form-group pos_rel mb-3">
                    <div>
                      <CustomField
                        name="email"
                        className="form-control lgn_input"
                        type="email"
                        placeholder="Correo electronico"
                      />
                      <FontAwesomeIcon icon="envelope" className="lgn_icon" />
                    </div>
                    {error && (
                      <h4 className="error-message mt-3">
                        No hay nungún usuario registrado con este correo
                        electronico
                      </h4>
                    )}
                    <CustomErrorMessage name="email" />
                  </div>
                )}

                <button
                  className="login-btn hover-btn h_50"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <ButtonLoading />
                  ) : token ? (
                    "Guardar nueva contraseña"
                  ) : (
                    "Recuperar contraseña"
                  )}
                </button>
              </>
            )}
          </Forms>
          <div className="signup-link">
            <p>
              <Link to="/login">Login</Link>
            </p>
          </div>
        </CardRegister>
      </LayoutLogin>
    </>
  );
};
export default ForgotPassword;
