const PreviosNext = ({ next, previous, save, status }) => {
  return (
    <>
      <div className="actions clearfix">
        <ul>
          <li
            onClick={previous}
            className={status.previous === 0 ? "d-none" : ""}
          >
            <span className="js-btn-next" title="PREV">
              Volver
            </span>
          </li>
          {status.next !== status.end ? (
            <li onClick={next}>
              <span className="js-btn-next" title="NEXT">
                Siguiente
              </span>
            </li>
          ) : (
            <li onClick={save}>
              <span className="js-btn-next" title="FINISH">
                Crear Catálogo
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default PreviosNext;
