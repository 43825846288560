import { InputText } from "primereact/inputtext";
import { Field } from "formik";

const InputTextField = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="control-label">
        {props.label}
      </label>
      <Field name={props.name}>
        {({ field, form }) => {
          const invalid = form.touched[field.name] && form.errors[field.name];
          return (
            <InputText
              {...field}
              {...props}
              className={invalid ? "w-100 p-invalid" : "w-100"}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default InputTextField;
