import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { FilterMatchMode } from "primereact/api";

const DataTableProducts = ({ data, loading, handleCurrentPage }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: null, matchMode: FilterMatchMode.CONTAINS },
    price: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const { total, per_page, from, data: products } = data ?? {};

  const onPageChange = ({ page }) => {
    handleCurrentPage(page);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <Paginator
        totalRecords={total}
        rows={per_page}
        first={from}
        onPageChange={onPageChange}
      />
    );
  };

  const imageBodyTemplate = (product) => {
    return (
      <img
        src={product.image}
        alt={product.name}
        loading="lazy"
        className="w-6rem shadow-2 border-round"
      />
    );
  };

  const formatCurrency = (value) => {
    return Number(value).toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
    });
  };

  const priceBodyTemplate = (product) => {
    return formatCurrency(product.price);
  };

  const header = renderHeader();

  const footer = renderFooter();

  return (
    <DataTable
      value={products}
      size="normal"
      showGridlines
      stripedRows
      removableSort
      dataKey="id"
      filterDisplay="row"
      emptyMessage="No se encontró ningún producto."
      globalFilterFields={["code", "name", "category", "price"]}
      filters={filters}
      header={header}
      footer={footer}
      loading={loading}
    >
      <Column
        field="image"
        header="Imagen"
        sortable
        body={imageBodyTemplate}
        style={{ width: "15%" }}
      ></Column>
      <Column
        field="code"
        header="Codigo"
        sortable
        style={{ width: "15%" }}
      ></Column>
      <Column
        field="name"
        header="Nombre"
        sortable
        style={{ width: "30%" }}
      ></Column>
      <Column
        field="category"
        header="Categoría"
        sortable
        style={{ width: "20%" }}
      ></Column>
      <Column
        field="price"
        header="Precio"
        body={priceBodyTemplate}
        sortable
        style={{ width: "20%" }}
      ></Column>
    </DataTable>
  );
};

export default DataTableProducts;
