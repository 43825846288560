import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LayoutQuestion from "../layout-questions/LayoutQuestion";
import PreviosNext from "../layout-questions/PreviousNext";
import ProgressBarArea from "../layout-questions/ProgressBarArea";
import Title from "../OptionQuestions/Title";
import Logo from "./../Logo/Logo";
import Question1 from "./Question1";
import Question2 from "./Question2";

import useAxios from "../hooks/useAxios";

import "./Extras.css";
import "./Question.css";

const Question = () => {
  const { data, loading, error, axiosFetch } = useAxios();

  const image = "../images/cataloguias-logo.png";
  const totalQuestions = 2;
  const initialPreviousNext = {
    previous: 0,
    next: 1,
    end: totalQuestions,
  };

  const [user, setUser] = useState(null);
  const [header, setHeader] = useState(null);
  const [csv, setCsv] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [previousNext, setPreviousNext] = useState(initialPreviousNext);
  const [file, setFile] = useState(null);
  const [showMessageRequiredCsv, setShowMessageRequiredCsv] = useState(false);
  const [showMessageRequiredFile, setShowMessageRequiredFile] = useState(false);

  useEffect(() => {
    setPercentage((previousNext.next * 100) / totalQuestions);
  }, [previousNext]);

  useEffect(() => {
    if (localStorage.getItem("user") && localStorage.getItem("header")) {
      const user = JSON.parse(localStorage.getItem("user"));
      const header = JSON.parse(localStorage.getItem("header"));
      setHeader(header);
      setUser(user);
    }
  }, []);

  const changeNextQuestion = () => {
    if (csv == null && previousNext.previous === 0) {
      setShowMessageRequiredCsv(true);
      setShowMessageRequiredFile(false);
      return;
    }

    setPreviousNext({
      previous: previousNext.previous + 1,
      next: previousNext.next + 1,
      end: totalQuestions,
    });
  };

  const changePreviousQuestion = () => {
    setPreviousNext({
      previous: previousNext.previous - 1,
      next: previousNext.next - 1,
      end: totalQuestions,
    });
  };

  const handleErrorUploaderCsv = () => {
    setShowMessageRequiredCsv(true);
    setCsv(null);
  };

  const handleErrorUploaderFile = () => {
    setShowMessageRequiredFile(true);
    setFile(null);
  };

  const handleChange = (files) => {
    setShowMessageRequiredFile(false);
    setFile(files);
  };

  const handleChangeCsv = (doc_file) => {
    setShowMessageRequiredCsv(false);
    setCsv(doc_file);
  };

  const saveQuestions = () => {
    if (file == null && previousNext.previous === 1) {
      setShowMessageRequiredFile(true);
      return;
    }

    setPreviousNext({
      previous: previousNext.previous + 1,
      next: previousNext.next + 1,
      end: totalQuestions,
    });

    const formData = new FormData();
    formData.append("csv", csv);
    for (let i = 0; i < file.length; i++) {
      formData.append("image[]", file[i]);
    }

    axiosFetch({
      url: `create-catalog/${header?.url}?format=2`,
      method: "post",
      body: formData,
      contentType: "multipart/form-data",
    });
  };

  return (
    <>
      <LayoutQuestion>
        <Link to="/mi-facturacion">
          <FontAwesomeIcon
            icon="arrow-left"
            style={{
              width: "40px",
              height: "33px",
            }}
          />
        </Link>
        <Logo image={image} />
        <Title title={user?.name} />

        <div className="quiz-option-selector clearfix">
          {previousNext.previous === 0 && (
            <Question1
              previousNext={previousNext}
              handleChangeCsv={handleChangeCsv}
              csv={csv}
              showMessageRequiredCsv={showMessageRequiredCsv}
              handleErrorUploaderCsv={handleErrorUploaderCsv}
            />
          )}

          {previousNext.previous === 1 && (
            <Question2
              previousNext={previousNext}
              handleChange={handleChange}
              file={file}
              showMessageRequiredFile={showMessageRequiredFile}
              handleErrorUploaderFile={handleErrorUploaderFile}
            />
          )}
        </div>

        {loading && (
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 d-flex justify-content-center">
              <div className="upload-doc-area clearfix">
                <div className="jw-doc-title text-center">
                  <h2 className="d-flex justify-content-center">
                    Cargando Informacion, espere por favor
                  </h2>
                  <div className="spinner-grow mt-3" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="col-md-2"></div>
          </div>
        )}

        {data && (
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 d-flex justify-content-center">
              <div className="upload-doc-area clearfix">
                <div className="jw-doc-title d-flex align-item-center justify-content-center text-center">
                  <h2>{data.message}</h2>
                </div>
              </div>
              <br />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4 d-flex flex-column justify-content-center">
              <br />
              <Link to="/mi-facturacion" className="col-md-12 btn btn-primary ">
                Ir al Tablero de Información
              </Link>
            </div>
            <div className="col-md-4"></div>
          </div>
        )}

        {error && (
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 d-flex justify-content-center">
              <div className="upload-doc-area clearfix">
                <div className="jw-doc-title d-flex align-item-center justify-content-center">
                  <h2>Parece que no hay internet. Reconectando...</h2>
                </div>
              </div>
              <br />
            </div>
          </div>
        )}

        {previousNext.previous !== 2 && (
          <ProgressBarArea progress={percentage} />
        )}

        {previousNext.previous !== 2 && (
          <PreviosNext
            next={changeNextQuestion}
            previous={changePreviousQuestion}
            save={saveQuestions}
            status={previousNext}
          />
        )}
      </LayoutQuestion>
    </>
  );
};

export default Question;
