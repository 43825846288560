import { useEffect, useState, useRef } from "react";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import DropdownFormField from "../../utils/DropdownFormField/DropdownFormField";
import InputNumberField from "../../utils/InputNumberField/InputNumberField";
import InputCalendarField from "../../utils/InputCalendarField/InputCalendarField";
import InputTextField from "../../utils/InputTextField/InputTextField";

import useAxios from "../../hooks/useAxios";

const CustomForm = ({ invoice, showMessageSuccess, rateDay }) => {
  const { values, setFieldValue, resetForm } = useFormikContext();
  const toast = useRef(null);

  const paymentMethodOptions = ["Efectivo", "Transferencia"];
  const currencyOptions = ["Dólares", "Bolivares", "Pesos"];

  useEffect(() => {
    if (invoice) {
      setFieldValue("debt", +invoice.total);
    }
  }, [invoice, setFieldValue]);

  useEffect(() => {
    if (showMessageSuccess) {
      resetForm();
      toast.current.show({
        severity: "success",
        summary: "Pago enviado correctamente",
        detail:
          "Su pago esta en revisión, nos contactaremos contigo lo antes posible. Gracias por confiar en Cataloguias",
        life: 3000,
      });
    }
  }, [showMessageSuccess, resetForm]);

  return (
    <Form>
      <Toast ref={toast} />
      <div className="add-cash-body pt-0 main-form">
        <div className="row">
          <div className="col-md-6 mt-4">
            <InputNumberField name="debt" label="Deuda" disabled />
          </div>
          <div className="col-md-6 mt-4">
            <InputNumberField name="pay" label="Abono" />
          </div>
          <div className="col-md-12 mt-4">
            <DropdownFormField
              name="payment_type"
              label="Forma de pago"
              options={paymentMethodOptions}
              placeholder="Seleccione una forma de pago"
            />
          </div>
          {values.payment_type && values.debt != 0 && (
            <>
              <div className="col-md-12 mt-4">
                <DropdownFormField
                  name="currency"
                  label="Tipo de moneda"
                  options={currencyOptions}
                  placeholder="Seleccione un metodó de pago"
                  rateDay={rateDay}
                />
              </div>

              {values.currency !== "" && (
                <>
                  {values.payment_type === "Transferencia" && (
                    <>
                      <div className="col-md-12 mt-4">
                        <InputTextField
                          name="bank_name"
                          label="En que banco fue hecha la transacción"
                          placeholder="Ingresé el nombre del banco"
                        />
                      </div>
                      <div className="col-md-12 mt-4">
                        <InputTextField
                          name="bank_reference"
                          label="Número de referencia"
                          placeholder="Ingresé el número de referencia"
                        />
                      </div>
                    </>
                  )}
                  {
                    <>
                      {values.currency !== "Dólares" && (
                        <>
                          <div className="col-md-4 mt-4">
                            <InputNumberField
                              name="rate"
                              label="Tasa del día"
                              disabled
                            />
                          </div>
                          <div className="col-md-4 mt-4">
                            <InputNumberField
                              name="rate_converter"
                              label="Total a pagar"
                              disabled
                              value={values.pay * values.rate}
                            />
                          </div>
                          <div className="col-md-4 mt-4">
                            <InputCalendarField name="date_payment" />
                          </div>
                        </>
                      )}
                    </>
                  }
                </>
              )}
            </>
          )}

          <div className="col-md-12">
            <button
              type="submit"
              className="next-btn16 hover-btn mt-4 d-block w-100"
            >
              Hacer pago
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};

const FormInvoice = ({ selectedInvoice }) => {
  const [showMessageSuccess, setShowMessageSuccess] = useState(null);

  const { data, axiosFetch } = useAxios();
  const { data: rateDay, axiosFetch: axiosFetchRate } = useAxios();
  const { data: invoice, axiosFetch: axiosFetchInvoice } = useAxios();

  useEffect(() => {
    if (selectedInvoice) {
      axiosFetchInvoice({
        url: `get-current-payment/${selectedInvoice.id}`,
      });
    }
  }, [selectedInvoice]);

  useEffect(() => {
    axiosFetchRate({
      url: "/list-rate",
    });
  }, []);

  useEffect(() => {
    if (data) {
      setShowMessageSuccess(true);
    }
  }, [data, rateDay]);

  const initialValues = {
    debt: 0,
    pay: "",
    payment_type: "",
    currency: "",
    bank_name: "",
    bank_reference: "",
    rate: "",
    rate_converter: 0,
    date_payment: new Date(),
  };

  const validationSchema = Yup.object({
    pay: Yup.number().min(1, "El montó mínimo de pago es de 1$"),
    payment_type: Yup.string().required("Debe seleccionar un metodó de pago"),
    currency: Yup.string().required("Debe seleccionar un tipo de moneda"),
    date_payment: Yup.date().required(),
    bank_name: Yup.string().when("payment_type", {
      is: "Transferencia",
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    bank_reference: Yup.string().when("payment_type", {
      is: "Transferencia",
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  });

  const handleSubmit = (values) => {
    values.date_payment = new Date(values.date_payment)
      .toISOString()
      .slice(0, 10);

    axiosFetch({
      url: `update-invoice/${invoice.invoice_id}`,
      method: "post",
      body: values,
    });
  };

  return (
    <>
      <div className="pdpt-title">
        <h4>Hacer pago</h4>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <CustomForm
          invoice={invoice}
          showMessageSuccess={showMessageSuccess}
          rateDay={rateDay}
        />
      </Formik>
    </>
  );
};

export default FormInvoice;
