import { useEffect, useState } from "react";

import Headers from "../../features/layout-landing/Header/Headers";
import BodyLanding from "../../features/layout-landing/BodyLanding/BodyLanding";

import ContentProduct from "../dashboard-products/ContentProduct";
import TitleContentProuct from "../dashboard-products/TitleContentProuct";
import LayoutDash from "./../dashboard/LayoutDash";
import DataTableProducts from "../dashboard-products/DataTableProducts";

import useAxios from "../../hooks/useAxios";

import "./Inventory.css";

const Inventory = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const url = `inventory?page=${currentPage + 1}`;

  const { data, loading, axiosFetch } = useAxios();

  useEffect(() => {
    axiosFetch({
      url,
    });
  }, [currentPage]);

  const handleCurrentPage = (value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Headers />

      <BodyLanding>
        <LayoutDash>
          <ContentProduct>
            <TitleContentProuct title={"Inventario"} />

            <div className="col-lg-12 col-md-12">
              <div className="pdpt-bg">
                <div className="active-offers-body">
                  <div className="table-responsive">
                    <DataTableProducts
                      data={data}
                      loading={loading}
                      handleCurrentPage={handleCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ContentProduct>
        </LayoutDash>
      </BodyLanding>
    </>
  );
};
export default Inventory;
