import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";

const Histories = ({ src, alt }) => {
  const [imageUser, setImageUser] = useState(null);

  const { data, axiosFetch } = useAxios();

  useEffect(() => {
    if (data) {
      localStorage.setItem("user", JSON.stringify(data));
    }
  }, [data]);

  const handleImageChange = (e) => {
    setImageUser(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    axiosFetch({
      method: "post",
      url: "edit-avatar",
      body: formData,
      contentType: "multipart/form-data",
    });
  };

  return (
    <>
      <div className="user-histories">
        <input
          type="file"
          onChange={handleImageChange}
          id="file-input"
          accept="image/*"
        />
        <img src={imageUser ?? src} alt={alt ?? ""} />
        <label className="edit-button" htmlFor="file-input">
          <FontAwesomeIcon icon="pen-square" className="edit-icon" />
        </label>
      </div>
    </>
  );
};
export default Histories;
