import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MessageRequiredFile = ({ message }) => {
  return (
    <>
      <div className="col-md-2"></div>
      <div className="col-md-8 d-flex justify-content-center mt-3">
        <div className="error-message mb-0">
          <FontAwesomeIcon icon="exclamation-circle" />
          <span>{message}</span>
        </div>
      </div>
      <div className="col-md-2"></div>
    </>
  );
};

export default MessageRequiredFile;
