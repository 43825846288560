
const ProgressBarArea=({progress})=>{
    return (
        <>
            <div className="quiz-progress-area">
                <div className="progress">
                    <div className="progress-bar position-relative" style={{width: progress+"%"}}>
                        <span>24% complete, keep it up!</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgressBarArea