
const LayoutLogin=({children})=>{
    return (
        <>
            <div className="wrapper position-relative sign-inup">
                <div className="wizard-content-1 clearfix">
				    

                    <div className="step-inner-content clearfix position-relative">
                        

                        <form className="multisteps-form__form" action="thank-you.html" id="wizard" method="POST">
                            <div className="form-area position-relative">
                                <div className="multisteps-form__panel  js-active" data-animation="fadeIn">
                                    <div className="wizard-forms clearfix position-relative">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LayoutLogin