import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faCoffee,
  faEnvelope,
  faArrowLeft,
} from "@fortawesome/fontawesome-free-solid";
const FooterLogin = () => {
  fontawesome.library.add(faCheckSquare, faCoffee, faEnvelope, faArrowLeft);

  return (
    <div className="copyright-text text-center mt-4">
      <FontAwesomeIcon icon="copyright" /> 2023{" "}
      <b>Zemper Codes C.A - RIF. J-503141212</b>. Todos los derechos reservados
    </div>
  );
};

export default FooterLogin;
