import BodyLanding from "../../features/layout-landing/BodyLanding/BodyLanding";
import Headers from "../../features/layout-landing/Header/Headers";
import ContentProduct from "../dashboard-products/ContentProduct";
import TitleContentProuct from "../dashboard-products/TitleContentProuct";
import LayoutDash from "./LayoutDash";
import "./Dashboard.css";
import FormUser from "./FormUser";

const Dashboard = () => {
  return (
    <>
      <Headers />

      <BodyLanding>
        <LayoutDash>
          <ContentProduct>
            <TitleContentProuct title={"Perfil"} />
            <FormUser />
          </ContentProduct>
        </LayoutDash>
      </BodyLanding>
    </>
  );
};
export default Dashboard;
