const ContentProduct = ({ children }) => {
  return (
    <div className="col-xl-12 col-lg-12 col-md-12">
      <div className="dashboard-right">
        <div className="row">{children}</div>
      </div>
    </div>
  );
};
export default ContentProduct;
