import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import useAxios from "../../hooks/useAxios";

const CustomFileUploader = ({ name, id, label, placeholder }) => {
  const [header, setHeader] = useState(null);

  const { axiosFetch } = useAxios();

  useEffect(() => {
    if (localStorage.getItem("header")) {
      setHeader(JSON.parse(localStorage.getItem("header")));
    }
  }, []);

  const handleChange = (file) => {
    if (header) {
      header[name] = file.name;
      localStorage.setItem("header", JSON.stringify(header));
    }

    const formData = new FormData();

    formData.append(name, file);

    let urlFetch = {
      portada_pdf: "portada",
      background_catalog: "fondo",
      footer_pdf: "footer",
    };

    axiosFetch({
      url: urlFetch[name],
      method: "post",
      body: formData,
      contentType: "",
    });
  };

  return (
    <div className="upload-doc-area clearfix">
      <label htmlFor={name} className="control-label">
        {label}
      </label>
      <div className="custom-file">
        <FileUploader
          label={placeholder}
          name={name}
          id={id}
          types={["png", "jpg"]}
          handleChange={handleChange}
        />
        {header && header[name]
          ? `Nombre del archivo: ${header[name]}`
          : "Aún no hay una imagen para subir"}
      </div>
    </div>
  );
};

export default CustomFileUploader;
