import { ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./ErrorMessage.css";

const CustomErrorMessage = ({ name, error }) => {
  return (
    <ErrorMessage name={name}>
      {(message) => (
        <div className={error ? "error-message" : "error-message-input"}>
          <FontAwesomeIcon icon="exclamation-circle" />
          <span>{error ? error.message : message}</span>
        </div>
      )}
    </ErrorMessage>
  );
};

export default CustomErrorMessage;
