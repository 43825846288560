import { useState } from "react";
import axios from "../api/jsonPH";

const useAxios = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token") ?? "";

  const Authorization = `Bearer ${token}`;

  const axiosFetch = async (options) => {
    const {
      url,
      method = "get",
      body = null,
      contentType = "application/json",
    } = options;

    try {
      setLoading(true);
      const response = await axios({
        url,
        method,
        data: body,
        headers: {
          "Content-Type": contentType,
          Authorization,
        },
      });
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error.response);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, axiosFetch };
};

export default useAxios;
