import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

import CardRegister from "../../features/layouts/Login/CardRegister";
import Forms from "../../features/layouts/Login/Forms";
import LayoutLogin from "../../features/layouts/Login/LayoutLogin";
import Logo from "../../features/layouts/Logo/Logo";
import CustomErrorMessage from "../../utils/ErrorMessage/ErrorMessage";
import CustomField from "../../utils/CustomField/CustomField";

import axios from "./../../api/jsonPH";
import useAxiosFunction from "./../../hooks/useAxiosFunction";
import ButtonLoading from "../../utils/ButtonLoading/ButtonLoading";
import InputPhoneField from "../../utils/InputPhoneField/InputPhoneField";

const Register = () => {
  const [data, , loading, axiosFetch] = useAxiosFunction();
  const navigate = useNavigate();

  const initialValues = {
    company_name: "",
    comercial_activity: "",
    email: "",
    phone: "",
    password: "",
  };

  const validationSchema = Yup.object({
    company_name: Yup.string()
      .trim()
      .required("Ingresé el nombre de la compañia"),
    comercial_activity: Yup.string()
      .trim()
      .required("Ingresé una actividad comercial"),
    email: Yup.string()
      .trim()
      .required("Ingresé un correo electronico")
      .email("Correo electronico inválido"),
    phone: Yup.number()
      .required("Ingresé un número de telefono")
      .min(1111111110, "Ingresé un número de telefono válido"),
    password: Yup.string()
      .required("Ingrese una contraseña")
      .min(8, "Minímo 8 caracteres"),
  });

  const handleSubmit = (values) => {
    axiosFetch({
      axiosInstance: axios,
      method: "post",
      url: "signup",
      requestConfig: values,
    });
  };

  useEffect(() => {
    if (data) {
      navigate("/login");
    }
  }, [data, navigate]);

  return (
    <LayoutLogin>
      <Logo />
      <CardRegister>
        <Forms
          title={"Regístrate"}
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
        >
          {data && <CustomErrorMessage error={data} />}
          <div className="form-group pos_rel mb-3">
            <div>
              <CustomField
                name="company_name"
                className="form-control lgn_input"
                type="text"
                placeholder="Nombre de la empresa"
              />
              <FontAwesomeIcon icon="user-circle" className="lgn_icon" />
            </div>
            <CustomErrorMessage name="company_name" />
          </div>
          <div className="form-group pos_rel mb-3">
            <div>
              <CustomField
                name="comercial_activity"
                className="form-control lgn_input"
                type="text"
                placeholder="Actividad comercial"
              />
              <FontAwesomeIcon icon="chess-queen" className="lgn_icon" />
            </div>
            <CustomErrorMessage name="comercial_activity" />
          </div>
          <div className="form-group pos_rel mb-3">
            <div>
              <CustomField
                name="email"
                className="form-control lgn_input"
                type="email"
                placeholder="Correo Electronico"
              />
              <FontAwesomeIcon icon="envelope" className="lgn_icon" />
            </div>
            <CustomErrorMessage name="email" />
          </div>
          <div className="mb-3">
            <InputPhoneField name="phone" style={{ height: "50px" }} />
            {/* <CustomErrorMessage name="phone" />  */}
          </div>
          <div className="form-group pos_rel mb-3">
            <div>
              <CustomField
                name="password"
                className="form-control lgn_input"
                type="password"
                placeholder="Contraseña"
                autoComplete="current-password"
              />
              <FontAwesomeIcon icon="lock" className="lgn_icon" />
            </div>
            <CustomErrorMessage name="password" />
          </div>
          <button
            className="login-btn hover-btn h_50"
            type="subbmit"
            disabled={loading}
          >
            {loading ? <ButtonLoading /> : "Registrar Empresa"}
          </button>
        </Forms>
        <div className="signup-link">
          <p>
            ¿Ya tienes cuenta? -{" "}
            <Link to="/login" className={loading ? "disabled" : ""}>
              Iniciar Sesión
            </Link>
          </p>
        </div>
      </CardRegister>
    </LayoutLogin>
  );
};
export default Register;
