import { BrowserRouter, Route, Routes,Link } from 'react-router-dom';

const Logo=({image})=>{
    return (
        <>
            <div className="sign-logo" id="logo">
                <Link to="/mi-perfil"><img src={image} alt=""/></Link>
            </div>
        </>
    )
}
export default Logo