import { Route, Routes } from "react-router-dom";

import ProtectedRoutes from "./components/hooks/protectedRoutes";

import Login from "./components/login-register/Login/Login";
import Register from "./components/login-register/Register/Register";
import ForgotPassword from "./components/login-register/ForgotPassword/ForgotPassword";

import Home from "./components/Home/Home";
import Question from "./components/Question/Question";
import Dashboard from "./components/landing/dashboard/Dashboard";
import Inventory from "./components/landing/inventory/Inventory";
import Invoice from "./components/landing/invoice/Invoice";

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="/mi-perfil" element={<Dashboard />} />
        <Route path="/mi-inventario" element={<Inventory />} />
        <Route path="/mi-facturacion" element={<Invoice />} /> 

        <Route path="/catalogo/:id" element={<Question />} />
      </Route>
    </Routes>
  );
};

export default App;
