import "./ButtonHamburguer.css";

const ButtonHamburguer = () => {
  return (
    <div style={{ width: "30px", height: "4px" }}>
      <input id="menu-toggle" type="checkbox" />
      <label className="menu-button-container" htmlFor="menu-toggle" />
      <div className="menu-button"></div>
    </div>
  );
};

export default ButtonHamburguer;
