import { BrowserRouter, Route, Routes,Link } from 'react-router-dom';

const Logo=()=>{
    return (
        <>
            <div className="sign-logo" id="logo">
                <Link to="/"><img src="./images/cataloguias-logo.png" alt=""/></Link>
            </div>
        </>
    )
}
export default Logo