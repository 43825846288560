import FooterLogin from "../FooterLogin/FooterLogin"
import "./LayoutLogin.css"
const LayoutLogin=({children})=>{
    return(
        <>
            <div className="sign-inup">
                <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="sign-form">
                                    <div className="sign-inner">
                                        {children}
                                    </div>
                                </div>
                                <FooterLogin />
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}

export default LayoutLogin