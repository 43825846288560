import { Formik, Form } from "formik";
import { Form as FormBootstrap } from "react-bootstrap/Form";

const Forms = ({
  children,
  title,
  initialValues,
  handleSubmit,
  validationSchema,
}) => {
  return (
    <div className="form-inpts checout-address-step">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <div className="form-title">
            <h6>{title}</h6>
          </div>
          {children}
        </Form>
      </Formik>
    </div>
  );
};

export default Forms;
