import { useEffect, useState } from "react";

import BodyLanding from "../../features/layout-landing/BodyLanding/BodyLanding";
import Headers from "../../features/layout-landing/Header/Headers";
import ContentProduct from "../dashboard-products/ContentProduct";
import TitleContentProuct from "../dashboard-products/TitleContentProuct";
import LayoutDash from "../dashboard/LayoutDash";
import DataTableInvoices from "./DataTableInvoices";
import FormInvoice from "./FormInvoice";
import HistoryInvoice from "./HistoryInvoice";

import useAxios from "../../hooks/useAxios";

import "./Invoice.css";

const Invoice = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const { data, loading, axiosFetch } = useAxios();

  const handleRowSelect = (e) => {
    if (e.data.paid < 1) {
      setSelectedInvoice(e.data);
    }
  };

  useEffect(() => {
    axiosFetch({
      url: `invoice?page=${currentPage + 1}`,
    });
  }, [currentPage]);

  const handleCurrentPage = (value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Headers />

      <BodyLanding>
        <LayoutDash>
          <ContentProduct>
            <TitleContentProuct title={"Facturación"} />

            <div className="col-lg-6 col-md-12">
              <div className="pdpt-bg">
                <div className="reward-body-dtt">
                  <div className="reward-img-icon">
                    <img src="images/money.svg" alt="" />
                  </div>
                  <span className="rewrd-title">Saldo Total Deudor</span>
                  <h4 className="cashbk-price">
                    ${data?.totalDebt.total_debt ?? 0}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="pdpt-bg">
                <div className="gambo-body-cash">
                  <div className="reward-img-icon">
                    <img
                      className="rotate-img"
                      src="images/coupon.svg"
                      alt=""
                    />
                  </div>
                  <span className="rewrd-title">
                    Cantidad de Página Creadas
                  </span>
                  <h4 className="cashbk-price">
                    {data?.totalDebitCount.quantity_pages ?? 0}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="pdpt-bg">
                <div className="pdpt-title">
                  <h4>Facturas</h4>
                </div>
                <div className="active-offers-body">
                  <div className="table-responsive">
                    <DataTableInvoices
                      data={data?.data}
                      loading={loading}
                      handleCurrentPage={handleCurrentPage}
                      selectedInvoice={selectedInvoice}
                      handleRowSelect={handleRowSelect}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="pdpt-bg">
                {data?.totalDebt.total_debt && selectedInvoice ? (
                  <FormInvoice selectedInvoice={selectedInvoice} />
                ) : (
                  <>
                    <div className="pdpt-title">
                      <h4>¿Cómo registrar un pago pendiente?</h4>
                    </div>
                    <div>
                      <ul className="history-list pending-invoice-list">
                        <li>
                          - En la tabla en la parte superior, selecciona la fila
                          correspondiente a la deuda pendiente que deseas pagar.
                        </li>
                        <li>
                          - Una vez seleccionada, aparecerá un formulario de
                          pago seguro y efectivo para que puedas registrar tu
                          pago.
                        </li>
                        <li>
                          - Completa el formulario siguiendo las instrucciones
                          detalladas y asegurándote de ingresar la información
                          correcta.
                        </li>
                        <li>
                          - Después de registrarlo, deberás esperar a que
                          nuestro equipo lo apruebe.
                        </li>
                        <li>
                          - Si tu pago es aprobado, podrás descargar tu catálogo
                          en formato PDF.
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>

            <HistoryInvoice />
          </ContentProduct>
        </LayoutDash>
      </BodyLanding>
    </>
  );
};
export default Invoice;
