import { InputNumber } from "primereact/inputnumber";
import { Field } from "formik";

const InputNumberWithFormik = ({ field, form, ...props }) => {
  const invalid = form.touched[field.name] && form.errors[field.name];

  let max;
  let currency = "USD";
  let locale = "en-US";

  if (field.name === "pay") {
    if (form.values.pay > form.values.debt) form.values.pay = form.values.debt;

    form.values.rate_converter = form.values.pay * form.values.rate;
    max = form.values.debt;
  }

  if (field.name === "rate" || field.name === "rate_converter") {
    if (form.values.currency === "Pesos") {
      currency = "COP";
      locale = "es-CO";
    } else if (form.values.currency === "Bolivares") {
      currency = "VEF";
      locale = "es-VE";
    }
  }

  return (
    <InputNumber
      {...props}
      {...field}
      onChange={(e) => form.setFieldValue(field.name, e.value)}
      className={invalid ? "w-100 p-invalid" : "w-100"}
      minFractionDigits={2}
      maxFractionDigits={2}
      mode="currency"
      currency={currency}
      locale={locale}
      max={max}
    />
  );
};

const InputNumberField = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="control-label">
        {props.label}
      </label>
      <Field {...props} component={InputNumberWithFormik}></Field>
    </div>
  );
};

export default InputNumberField;
