import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LayoutHome from "./LayoutHome/LayoutHome";

const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      navigate("/mi-facturacion");
    } else {
      navigate("/login");
    }
  }, [navigate, token]);

  return (
    <>
      <LayoutHome></LayoutHome>
    </>
  );
};

export default Home;
