import { Calendar } from "primereact/calendar";
import { Field } from "formik";
import { addLocale } from "primereact/api";

const InputCalendarField = ({ name }) => {
  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  return (
    <div className="form-group">
      <label className="control-label">Fecha del pago</label>
      <Field name={name}>
        {({ field, form }) => {
          const invalid = form.touched[name] && !field.value;
          return (
            <Calendar
              {...field}
              dateFormat="dd/mm/yy"
              className={invalid ? "w-100 p-invalid" : "w-100"}
              locale="es"
              onChange={(e) => form.setFieldValue(name, e.value)}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default InputCalendarField;
