import { Col, Container, Row } from "react-bootstrap"


const LayoutHome=({children})=>{
    return (
        <>
            <Col>
                <Row>
                    
                    {children}
                </Row>
            </Col>
        </>
    )
}
export default LayoutHome