import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

import CardRegister from "../../features/layouts/Login/CardRegister";
import Forms from "../../features/layouts/Login/Forms";
import LayoutLogin from "../../features/layouts/Login/LayoutLogin";
import Logo from "../../features/layouts/Logo/Logo";
import CustomErrorMessage from "../../utils/ErrorMessage/ErrorMessage";
import CustomField from "../../utils/CustomField/CustomField";
import ButtonLoading from "../../utils/ButtonLoading/ButtonLoading";

import axios from "./../../api/jsonPH";
import useAxiosFunction from "./../../hooks/useAxiosFunction";

const Login = () => {
  const [data, error, loading, axiosFetch] = useAxiosFunction();

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Ingresé un correo electronico")
      .email("Correo electronico inválido"),
    password: Yup.string()
      .required("Ingrese una contraseña")
      .min(8, "Minímo 8 caracteres"),
  });

  const handleSubmit = (values) => {
    axiosFetch({
      axiosInstance: axios,
      method: "post",
      url: "login",
      requestConfig: values,
    });
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("header", JSON.stringify(data.header));
      localStorage.setItem("user", JSON.stringify(data.user));

      navigate("/mi-facturacion");
    }
  }, [data, navigate]);
  return (
    <>
      <LayoutLogin>
        <Logo />
        <CardRegister>
          <Forms
            title={"Iniciar Sesión"}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
          >
            {error && <CustomErrorMessage error={error.data} />}
            <div className="form-group pos_rel mb-3">
              <div>
                <CustomField
                  name="email"
                  className="form-control lgn_input"
                  type="email"
                  placeholder="Correo electronico"
                  autoComplete="username"
                />
                <FontAwesomeIcon icon="envelope" className="lgn_icon" />
              </div>
              <CustomErrorMessage name="email" />
            </div>
            <div className="form-group pos_rel mb-3">
              <div>
                <CustomField
                  name="password"
                  className="form-control lgn_input"
                  type="password"
                  placeholder="Contraseña"
                  autoComplete="current-password"
                />
                <FontAwesomeIcon icon="lock" className="lgn_icon" />
              </div>
              <CustomErrorMessage name="password" />
            </div>
            <button
              className="login-btn hover-btn h_50"
              type="submit"
              disabled={loading}
            >
              {loading ? <ButtonLoading /> : "Iniciar Sesión"}
            </button>
          </Forms>
          <div className="password-forgor">
            <Link to="/forgot-password" className={loading ? "disabled" : ""}>
              Olvidé mi contraseña
            </Link>
          </div>
          <div className="signup-link">
            <p>
              ¿No tienes cuenta? -{" "}
              <Link to="/register" className={loading ? "disabled" : ""}>
                Regístrate
              </Link>
            </p>
          </div>
        </CardRegister>
      </LayoutLogin>
    </>
  );
};

export default Login;
