const TitleContentProuct = ({ title }) => {
  return (
    <div className="col-md-12">
      <div className="main-title-tab">
        <h4>
          <i className="uil uil-gift"></i>
          {title}
        </h4>
      </div>
    </div>
  );
};
export default TitleContentProuct;
