import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Offcanvas } from "react-bootstrap";

import "./LogoLanding.css";
import useAxios from "../../../hooks/useAxios";
import axios from "../../../api/jsonPH";

import ButtonHamburguer from "../../../utils/ButtonHamburguer/ButtonHamburguer";
import ButtonLoading from "../../../utils/ButtonLoading/ButtonLoading";

const LogoLanding = () => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(true);
  const [header, setHeader] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data, axiosFetch } = useAxios();

  const toast = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("header")) {
      setHeader(JSON.parse(localStorage.getItem("header")));
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.status === false) {
        toast.current.show({
          severity: "error",
          summary: data.message,
          detail: `Cancela tu factura N°: ${data.invoice.id}, para poder descargar el catálago`,
          life: 3000,
        });
        setLoading(false);
        return;
      }

      const download = async () => {
        const response = await axios
          .get(`generate-pdf/${header?.url}`, {
            responseType: "blob",
          })
          .finally(() => {
            setLoading(false);
          });

        saveAs(response.data, `${header?.url}.pdf`);
      };

      download();
    }
  }, [data]);

  const menu = [
    {
      id: 2,
      name: "Mi Perfil",
      icon: "home",
      route: "/mi-perfil",
    },
    {
      id: 9,
      name: "Crear Catálogo",
      icon: "money-bill",
      route: "/catalogo/" + header?.url,
    },
    {
      id: 1,
      name: "Facturación",
      icon: "table",
      route: "/mi-facturacion",
    },
    {
      id: 8,
      name: "Mi Inventario",
      icon: "hashtag",
      route: "/mi-inventario",
    },
  ];

  const handleShowMenu = () => setShow(!show);

  const handleDownloadPDF = () => {
    setLoading(true);

    axiosFetch({
      url: `generate-pdf/${header?.url}`,
    });
  };

  const closeSession = () => {
    localStorage.clear();
  };

  const changeMode = (item) => {
    if (item?.change) {
      if (status) {
        document.body.classList.add(item.change);
        setStatus(false);
      } else {
        document.body.classList.remove(item.change);
        setStatus(true);
      }
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="top-header-group">
        <div className="top-header">
          <div className="main_logo" id="logo">
            <Link to="/mi-facturacion">
              <img
                src="/images/cataloguias-logo-3.png"
                alt="Logo Cataloguías"
              />
            </Link>
            <Link to="/mi-facturacion">
              <img
                className="logo-inverse"
                src="/images/cataloguias-logo-5.png"
                alt="Logo Inverso Cataloguías"
              />
            </Link>
          </div>

          <div className="header_right">
            <ul>
              <li style={{ maxHeight: "40px" }}>
                <button
                  className="offer-link next-btn16 hover-btn"
                  onClick={handleDownloadPDF}
                  disabled={loading}
                >
                  {loading ? (
                    <ButtonLoading />
                  ) : (
                    <>
                      <FontAwesomeIcon icon="gift" />
                      <i></i>Descargar PDF
                    </>
                  )}
                </button>
              </li>

              <li
                className="dropdown account-dropdown"
                onClick={handleShowMenu}
              >
                <button className="next-btn15">
                  {header?.company}
                  <ButtonHamburguer />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Offcanvas placement={"end"} show={show} onHide={handleShowMenu}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ width: "100%" }}>
            <div class="offcanvas-header">
              <div class="offcanvas-logo" id="offcanvasNavbarLabel">
                <img
                  src="/images/cataloguias-logo-3.png"
                  alt="Logo Cataloguías"
                />
              </div>
              <button type="button" class="close-btn" onClick={handleShowMenu}>
                <FontAwesomeIcon icon="window-close" />
              </button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-category mb-4 d-block d-lg-none">
            <div className="offcanvas-search position-relative">
              <input
                className="canvas_search"
                type="text"
                placeholder="Search for products.."
              />

              <FontAwesomeIcon
                icon="search"
                className="hover-btn canvas-icon"
              />
            </div>
          </div>
          <div className="d-block ">
            <ul className="offcanvas-help-links">
              {menu.map((item) => (
                <li key={item.id} onClick={() => changeMode(item)}>
                  <Link to={item.route} className="channel_item">
                    <FontAwesomeIcon icon={item.icon} className="icon__1" />
                    {item.name}
                  </Link>
                </li>
              ))}
              <li>
                <Link to="/" className="channel_item" onClick={closeSession}>
                  <FontAwesomeIcon icon="arrow-right" className="icon__1" />
                  Cerrar Sesión
                </Link>
              </li>
            </ul>

            <div className="offcanvas-copyright">
              <div className="copyright-text text-center mt-4">
                <FontAwesomeIcon icon="copyright" /> 2023{" "}
                <b>Zemper Codes C.A - RIF. J-503141212</b>. Todos los derechos
                reservados
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LogoLanding;
