import { FileUploader } from "react-drag-drop-files";
import MessageRequiredFile from "./MessageRequiredField";

const Question1 = ({
  previousNext,
  handleChangeCsv,
  csv,
  showMessageRequiredCsv,
  handleErrorUploaderCsv,
}) => {
  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8 d-flex justify-content-center">
        <div className="upload-doc-area clearfix">
          <div className="jw-doc-title">
            <h2>Paso {previousNext.previous + 1}: Subir Archivo (Excel)</h2>
            <p>( Formato Permitidos: xlsx )</p>
          </div>
          <div className="custom-file">
            <FileUploader
              handleChange={handleChangeCsv}
              label="Arrastre y suelte el archivo xlsx ó haga click/"
              name="doc_file"
              types={["xlsx"]}
              onTypeError={handleErrorUploaderCsv}
            />
            {csv
              ? `Nombre del archivo: ${csv.name}`
              : "Aún no hay archivos para subir"}
          </div>
        </div>
      </div>
      <div className="col-md-2"></div>

      {/* Message Error */}
      {showMessageRequiredCsv && (
        <MessageRequiredFile message="Debe ingresar un archivo excel." />
      )}
    </div>
  );
};

export default Question1;
