import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import es from "react-phone-input-2/lang/es.json";

const InputPhoneField = (props) => {
  return (
    <div>
      {props.label && (
        <label htmlFor={props.name} className="control-label">
          {props.label}
        </label>
      )}
      <Field name={props.name}>
        {({ field, form }) => {
          return (
            <PhoneInput
              {...field}
              inputProps={{
                name: props.name,
              }}
              defaultCountry="ve"
              country={"ve"}
              defaultMask="... ... ...."
              localization={es}
              onChange={(e) => form.setFieldValue(field.name, e)}
              inputClass="w-100"
              inputStyle={props.style}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default InputPhoneField;
