import LogoLanding from "./LogoLanding";

const Headers = () => {
  return (
    <header className="header clearfix">
      <LogoLanding />
    </header>
  );
};
export default Headers;
