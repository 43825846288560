import { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";

import Histories from "../../features/all-feature/Histories";

import InputPhoneField from "../../utils/InputPhoneField/InputPhoneField";
import InputTextField from "../../utils/InputTextField/InputTextField";
import ButtonLoading from "../../utils/ButtonLoading/ButtonLoading";

import useAxios from "../../hooks/useAxios";
import CustomFileUploader from "../../utils/CustomFileUploader/CustomFileUploader";

const validationSchema = Yup.object({
  company_name: Yup.string().required(),
  email: Yup.string().email().required(),
  dni: Yup.string().required(),
  address: Yup.string().required(),
  phone: Yup.string().required(),
  categoria: Yup.string().required(),
});

const FormUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const toast = useRef(null);

  const { data, loading, error, axiosFetch } = useAxios();

  useEffect(() => {
    if (data) {
      toast.current.show({
        severity: "success",
        summary: "El perfil se actualizó correctamente",
        detail: "",
        life: 3000,
      });
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("header", JSON.stringify(data.header));
    }

    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Ocurrio un error inesperado, por favor intenté de nuevo",
        life: 3000,
      });
    }
  }, [data, error]);

  const initialValues = {
    company_name: user?.name ?? "",
    email: user?.email ?? "",
    dni: user?.rif_dni ?? "",
    address: user?.fiscal_address ?? "",
    phone: user?.phone ?? "",
    categoria: user?.category ?? "",
    instagram: user?.instagram ?? "",
    twitter: user?.twitter ?? "",
  };

  const handleSubmit = (values) => {
    // Edit info
    axiosFetch({
      method: "post",
      url: "users",
      body: values,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <Form>
            <Toast ref={toast} />
            <div className="row justify-content-end">
              <div className="d-flex justify-content-center col-lg-6 mt-4">
                <Histories src={user?.avatar} alt={user?.name} />
              </div>

              <div className="col-lg-6"></div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="company_name"
                  id="company_name"
                  label="Nombre de la empresa"
                  placeholder="Ingresé el nombre de la empresa"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="email"
                  id="email"
                  label="Email"
                  placeholder="Ingresé el correo electronico"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="dni"
                  id="dni"
                  label="RIF/C.I/DNI"
                  placeholder="Ingresé el RIF/C.I/DNI"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="address"
                  id="address"
                  label="Dirección Fiscal"
                  placeholder="Ingresé la dirreción de la empresa"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputPhoneField
                  name="phone"
                  id="phone"
                  label="Teléfono"
                  style={{ height: "35px" }}
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="categoria"
                  id="categoria"
                  label="Categoría"
                  placeholder="Ingresé la categoría de la empresa"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="instagram"
                  id="instagram"
                  label="Instagram"
                  placeholder="Ingresé la url de instagram"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <InputTextField
                  name="twitter"
                  id="twitter"
                  label="Twitter"
                  placeholder="Ingresé la url de twitter"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <CustomFileUploader
                  name="portada_pdf"
                  label="Imagen de portada (Opcional)"
                  placeholder="Ingresé una imagen de portada"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <CustomFileUploader
                  name="background_catalog"
                  label="Imagen de Fondo (Opcional)"
                  placeholder="Ingresé una imagen de fondo"
                />
              </div>

              <div className="col-lg-6 mt-4">
                <CustomFileUploader
                  name="footer_pdf"
                  label="Imagen de pie de página (Opcional)"
                  placeholder="Ingresé una imagen de pie de página"
                />
              </div>

              <div className="col-lg-6"></div>

              <div className="col-lg-4 mt-4">
                <button
                  className="login-btn hover-btn h_50"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <ButtonLoading /> : "Actualizar Información"}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormUser;
