import { useEffect } from "react";
import useAxios from "../../hooks/useAxios";

const HistoryInvoice = () => {
  const { data, axiosFetch } = useAxios();

  useEffect(() => {
    axiosFetch({
      url: "payment",
    });
  }, []);

  const formatCurrency = (payment, currency) => {
    let codCurrency = "USD";
    let currencyLocale = "en-US";

    if (currency === "Pesos") {
      codCurrency = "COP";
      currencyLocale = "es-CO";
    } else if (currency === "Bolivares") {
      codCurrency = "VEF";
      currencyLocale = "es-VE";
    }

    return Number(payment).toLocaleString(currencyLocale, {
      style: "currency",
      currency: codCurrency,
    });
  };

  return (
    <div className="col-lg-6 col-md-12">
      <div className="pdpt-bg">
        {data?.length > 0 ? (
          <>
            <div className="pdpt-title">
              <h4>Historial de Pagos</h4>
            </div>
            <div className="history-body scrollstyle_4">
              <ul className="history-list">
                {data.map((payment, index) => (
                  <li key={index}>
                    <div className="purchase-history">
                      <div className="purchase-history-left">
                        <h4>Factura: {payment.invoice_id}</h4>
                        <h4>{payment.payment_type}</h4>
                        {payment.bank_name && (
                          <p>
                            {payment.bank_name} -{" "}
                            <ins>{payment.bank_reference}</ins>
                          </p>
                        )}

                        <span>
                          {new Date(payment.date_payment).toLocaleDateString(
                            "es-ES"
                          )}
                        </span>
                      </div>
                      <div className="purchase-history-right">
                        <span>
                          {formatCurrency(
                            payment.rate_converter,
                            payment.currency
                          )}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default HistoryInvoice;
