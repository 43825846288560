import { FileUploader } from "react-drag-drop-files";
import MessageRequiredFile from "./MessageRequiredField";

const Question2 = ({
  previousNext,
  handleChange,
  file,
  showMessageRequiredFile,
  handleErrorUploaderFile,
}) => {
  const fileTypes = ["JPG", "PNG", "GIF"];

  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8 d-flex justify-content-center">
        <div className="upload-doc-area clearfix">
          <div className="jw-doc-title">
            <h2>Paso {previousNext.previous + 1}: Subir Fotos</h2>
            <p>( Archivo Permitidos: .JPG,.PNG )</p>
          </div>
          <div className="custom-file">
            <FileUploader
              multiple={true}
              handleChange={handleChange}
              label="Arrastre y suelte las imagenes ó haga click/"
              name="file"
              types={fileTypes}
              onTypeError={handleErrorUploaderFile}
            />
            {file
              ? `Total Archivos a subir: ${file.length}`
              : "Aun no hay archivos para subir"}
          </div>
        </div>
        <br />
      </div>
      <div className="col-md-2"></div>

      {/* Message Error */}
      {showMessageRequiredFile && (
        <MessageRequiredFile message="Debe ingresar las imágenes con los formatos png, jpg." />
      )}
    </div>
  );
};

export default Question2;
