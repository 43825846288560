const Title = ({ title }) => {
  return (
    <>
      <div className="quiz-title text-center mt-3">
        <h2>{title}</h2>
      </div>
    </>
  );
};
export default Title;
