import { Field } from "formik";

import "./CustomField.css";

const CustomField = ({
  name,
  placeholder,
  className,
  type = "text",
  autoComplete,
}) => {
  return (
    <Field name={name}>
      {({ form, meta }) => {
        return (
          <input
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            className={className}
            autoComplete={autoComplete}
            {...form.getFieldProps(name)}
            style={{
              borderColor: meta.error && meta.touched ? "red" : "",
            }}
          />
        );
      }}
    </Field>
  );
};

export default CustomField;
